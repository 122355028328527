import * as React from "react";
import { useState } from "react";

import Step1 from "./pdfSteps/Step1";
import Step2 from "./pdfSteps/Step2";
import Step3 from "./pdfSteps/Step3";
import Step4 from "./pdfSteps/Step4";
import Step5 from "./pdfSteps/Step5";
import Step6 from "./pdfSteps/Step6";
import Step7 from "./pdfSteps/Step7";
import Step8 from "./pdfSteps/Step8";
import Step9 from "./pdfSteps/Step9";
import IconArrowList from "./icons/IconArrowList";

export default function PDFForm({}) {
  const [step, setStep] = useState(0);
  const [overdueDate, setOverdueDate] = useState(true);
  const [formData, setFormData] = useState({
    fullname: '',
    registrationAddress: '',
    differentAddress: 'same',
    diffAddressReason: '',
    personalAddress: '',
    personalAddress2: '',
    email: '',
    phone: '',
    birthday: '',
    specificDate: 'false',
    dateOn: '',
    dateUntil: '',
    signatureReason: '',
    helperName: '',
    helperAddress: ''
  });

  const handleChange = (e) => {
    // Set Form Data
    if (e.target.name === 'differentAddress') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        personalAddress: '',
        personalAddress2: '',
        diffAddressReason: ''
      });
      return;
    }
    
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleClick = (e) => {
    e.preventDefault();
    const target = e.target.id;
    target === 'continue' && setStep(step + 1);
    target === 'back' && setStep(step - 1);
  }

  const returnStep = () => {
    switch (step) {
      case 0:
        return <Step1 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>

      case 1:
        return <Step2 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)} setFormData={setFormData}/>
    
      case 2:
        return <Step3 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
    
      case 3:
        return <Step4 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
    
      case 4:
        return <Step5 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
    
      case 5:
        return <Step6 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)} setFormData={setFormData}/>

      case 6:
        return <Step7 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>

      case 7:
        return <Step8 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>

      case 8:
        return <Step9 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)} setFormData={setFormData}/>
    
      default:
        return <Step1 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
        break;
    }
  }


  return(
    <section className="overflow-steps overflow-steps--page">
      <div className="container">
        <div className="row">
          {
            !overdueDate ?

              <div className={`col-md-7 steps-content steps-content-${step}`}>
                <div className="overflow__intro">
                  <h2>The deadline to apply for a postal vote has now passed</h2>
                  <p>Your postal vote must be received at the Guildhall by 8pm on 24th March or can be dropped at your polling station between 8am and 8pm on Election Day.</p>
                </div>
              </div>

            :
            <>
              <div className={`col-md-4 steps-content steps-content-${step}`}>
                <div className="overflow__intro">
                  <h2>Apply for a postal vote</h2>
                  <p>Please, provide all of the information requested. By the end of the process, a print-ready PDF document will be generated.</p>
                  {/* FORMER TEXT: <p>You MUST print a physical copy of the document and sign it by hand with BLACK ink, and send it back to us by mail or email by 5pm on the 11th working day before the election you want to vote in..</p> */}
                  <p>You MUST print a physical copy of the document and sign it by hand with BLACK ink, and send it back to us by mail or email.</p>
                </div>
                <div className="overflow__list">
                  <h2>Remember</h2>
                  <ul>
                    <li><IconArrowList/>Fill in the form</li>
                    <li><IconArrowList/>Print it out</li>
                    <li><IconArrowList/>Sign it by hand</li>
                    <li><IconArrowList/>Send it back to us by email or post</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 offset-md-1">
                {
                  returnStep()
                }
              </div>
            </>
          }

        </div>
      </div>
    </section>
  )
}